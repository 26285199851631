"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImageUrl, getImageUrlNew, imageMimeTypes } from "../lib/util";
import Image from "next/image";
import dynamic from "next/dynamic";

const VideoPlayer = dynamic(() => import("./VideoPlayer"), {
  ssr: true,
});

const SwiperCarousel = ({ media = [], loading, priority }) => {
  return (
    <Swiper
      centeredSlides
      loop={true}
      autoplay={{
        delay: 500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        320: {
          slidesPerView: 1.3,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1.4,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 1.6,
          spaceBetween: 50,
        },
      }}
    >
      {media.map((data, index) => (
        <SwiperSlide className="relative" key={index}>
          {({ isActive }) => (
            <>
              <div
                className="w-full h-[200px] lg:h-[447px] max-h-full relative overflow-hidden rounded-2xl"
                // style={{ height: "auto !important" }}
              >
                {imageMimeTypes.includes(data?.attachment?.mime) ? (
                  <Image
                    loading={loading}
                    src={getImageUrlNew(data?.attachment, "large")}
                    fill
                    alt="swiper"
                    objectFit="cover"
                    className="rounded-[16px]"
                    priority={priority}
                  />
                ) : (
                  <div className="absolute inset-0">
                    {data?.attachment && (
                      <VideoPlayer
                        src={getImageUrl(data?.attachment)}
                        playPosition="right"
                        controls={isActive}
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default SwiperCarousel;

{
  /* <SwiperSlide className="relative" key={index}>
{({ isActive }) => (
  <>
    <div className="yewalidiv w-full h-[200px] lg:h-[447px] max-h-full relative overflow-hidden rounded-[16px]"   
          //             style={{
          //   height: "auto !important",

          // }}
           >
      {imageMimeTypes.includes(data?.attachment?.mime) ? (
        <Image
          src={getImageUrl(data?.attachment)}
          fill
          alt=""
          objectFit="cover"
          className="rounded-[16px]"
        />
      ) : (
        <video
        className="codehere"
          src={getImageUrl(data?.attachment)}
          playPosition="right"
          controls={isActive}
          style={{
            height: "auto !important",
            width: "auto",
            maxWidth: "794.66px",
          }}
        />
      )}
    </div>
  </>
)}
</SwiperSlide> */
}
