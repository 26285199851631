import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/Academy.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/AccreditationsCarousel.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/BlogCarousel.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/ClientReview.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/DemandCourses.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/HomeAboutSec.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/lp-frontend/src/components/LazyLoader.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/LpTalks.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/molecules/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/lp-frontend/src/components/molecules/RightEnrollWrapper.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/ourClients/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/lp-frontend/src/components/SwiperCarousel.jsx");
