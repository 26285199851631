import React from "react";

const OurClientsSkeleton = () => {
  return (
    <div className="py-default animate-pulse">
      <div className="grid lg:grid-cols-5 gap-8 lg:gap-12 p-10 lg:p-20 bg-[#1A1414]">
        {/* Left section for the client sliders */}
        <div className="col-span-1 lg:col-span-3 overflow-hidden relative place-content-center">
          <div className="grid grid-rows-4 gap-y-12 p-default">
            {[...Array(4)].map((_, i) => (
              <div
                key={i}
                className="w-full h-[80px] bg-gray-300 rounded-md"
              ></div>
            ))}
          </div>
        </div>

        {/* Right section with text placeholders */}
        <div className="col-span-1 lg:col-span-2 flex flex-col justify-end order-first lg:order-last">
          <div className="space-y-6">
            {/* Avatar Placeholder */}
            <div className="w-16 h-16 bg-gray-300 rounded-full"></div>
            {/* Title Placeholder */}
            <div className="w-3/4 h-10 bg-gray-300 rounded-full"></div>
            {/* Description Placeholder */}
            <div className="space-y-3">
              <div className="w-full h-4 bg-gray-300 rounded-md"></div>
              <div className="w-3/4 h-4 bg-gray-300 rounded-md"></div>
            </div>
            {/* Button Placeholder */}
            <div className="w-1/3 h-10 bg-gray-300 rounded-md"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurClientsSkeleton;
