import { stringify } from "qs";
import endpoints from "../../lib/endpoints";
import getRequest, { makeApiRequest } from "../../lib/fetchClient";
import { baseUrl } from "../../lib/util";

export const getLpTalks = async (params) => {
  const qParams = {
    populate: "*",
  };
  // const querySting = qs.stringify(qParams, { encodeValuesOnly: true });
  const querySting = stringify(qParams, { encodeValuesOnly: true });

  try {
    return await getRequest(baseUrl + endpoints.lpTalks + `?${querySting}`, {
      cache: "no-cache",
      lang: params?.lang,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getLpTalk = async (id, params) => {
  const qParams = {
    populate: "*",
  };

  // const querySting = qs.stringify(qParams, { encodeValuesOnly: true });
  const querySting = stringify(qParams, { encodeValuesOnly: true });

  try {
    const res = await getRequest(
      baseUrl + endpoints.lpTalks + `/${id}` + `?${querySting}`,
      {
        cache: "no-cache",
        lang: params?.lang,
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const registerLptalksAction = async (formData) => {
  try {
    const reqBody = {
      data: formData,
    };

    return await makeApiRequest(
      baseUrl + endpoints.lpTalksRegistration,
      "POST",
      reqBody
    );
  } catch (error) {
    console.log(error);
  }
};
