"use client";
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import {
  BgImg3Wrap,
  ContactWrapShape,
  ContentContainer,
  Layout,
  SendBtn,
  Stack,
} from "./molecules";

const GlobalCourosel = dynamic(() => import("./Caurosel/globalCourosel"));
const TalksCard = dynamic(() => import("./TalkCard"));

import { useDictionary } from "../context/dictionaryProvider";
import {
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from "@nextui-org/react";
import InputTextField from "./atoms/InputTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getLpTalks, registerLptalksAction } from "../actions/lpTalks";
import { Navigation } from "swiper/modules";
import ThankuModal from "./modal/ThankuModal";
import { isPhoneValid } from "../lib/validatePhone";
import PhoneField from "./atoms/PhoneField";
import { bitrixLeadAction } from "../actions/bitrix";
import { bitrixSourceIds } from "../lib/util";

const talksRegisterScema = yup
  .object({
    name: yup
      .string()
      .required()
      .min(3, "Name must be at least 3 characters long")
      .max(25, "Name cannot exceed 25 characters")
      .matches(/^[a-zA-Z0-9\s]+$/, "No special character allowed"),
    email: yup
      .string()
      .email()
      .required()
      .matches(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Email must have a valid domain"
      ),
    phone: yup
      .string()
      .required("Phone number is required")
      .test("is-valid-phone", "Phone number is not valid", (value) => {
        if (!value) return false; // Handle empty value if required
        return isPhoneValid(value);
      }),
    message: yup
      .string()
      .trim()
      .required()
      .matches(/^[a-zA-Z0-9\s]+$/, "No special character allowed"),
  })
  .required();

const LpTalks = ({ params, searchParams }) => {
  const dict = useDictionary();
  const [talks, setTalks] = useState(null);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [thankyou, setThankyou] = useState(false);

  const [selectedTalk, setselectedTalk] = useState({
    lpTalk: null,
    slotDate: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLpTalks(params);
        setTalks(response || null);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [params]);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(talksRegisterScema),
  });

  const onsubmit = async (data) => {
    try {
      const formData = {
        ...data,
        locale: "en",
        lp_talk: { connect: [selectedTalk.lpTalk?.id] },
        slot_time: selectedTalk.slotDate?.slot_date,
        slot_id: selectedTalk.slotDate?.id,
      };
      const res = await registerLptalksAction(formData);

      await bitrixLeadAction(
        { ...data, course: selectedTalk?.lpTalk },
        searchParams,
        bitrixSourceIds.lpTalk
      );

      if (res) setThankyou((prev) => !prev);
      if (res) {
        reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Layout className="lpTalks mt-20 md:mt-48 mb-20 relative">
        <BgImg3Wrap />
        <ContentContainer>
          <Stack>
            <h2 className="subHeading w-full text-center mb-11 text-[32px] leading-[32px] md:text-5xl font-extrabold text-black">
              {dict?.talks?.title}
            </h2>
            <GlobalCourosel
              data={talks?.data}
              modules={[Navigation]}
              navigation={talks?.data.length > 2}
              pagination={{
                clickable: true,
              }}
              loop={true}
              className="spacing customClass lpTalks"
              style={{ position: "unset" }}
              element={
                <TalksCard
                  selectedTalk={selectedTalk}
                  setselectedTalk={setselectedTalk}
                  onPress={onOpen}
                />
              }
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
            />
          </Stack>
        </ContentContainer>
        {isOpen && (
          <Modal
            backdrop="blur"
            isOpen={isOpen}
            size="xl"
            onOpenChange={onOpenChange}
            classNames={{
              backdrop: "blur-md",
            }}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  {/* <ModalHeader className="flex flex-col gap-1 ">Modal Title</ModalHeader> */}
                  <ModalBody>
                    {!thankyou ? (
                      <form
                        onSubmit={handleSubmit(onsubmit)}
                        className="relative bg-[#fff] my-12 mx-10"
                      >
                        <div className="px-12 pb-28 pt-10 rounded-2xl border border-black">
                          <p className="text-2xl text-[#303030] mb-5 font-bold">
                            Register for lp talks
                          </p>
                          <InputTextField
                            label={"Name"}
                            type={"text"}
                            {...register("name", { required: true })}
                            aria-invalid={errors.name ? "true" : "false"}
                            isInvalid={!!errors?.name}
                            errorMessage={errors?.name?.message}
                          />
                          <InputTextField
                            label={"Email"}
                            type={"email"}
                            {...register("email", { required: true })}
                            aria-invalid={errors.email ? "true" : "false"}
                            isInvalid={!!errors?.email}
                            errorMessage={errors?.email?.message}
                          />
                          <PhoneField
                            label={dict?.form.fieldNames.phone}
                            value={getValues("phone")}
                            onChange={async (value) => {
                              setValue("phone", value, {
                                shouldDirty: true,
                                shouldValidate: isDirty,
                              });
                            }}
                            isInvalid={!!errors?.phone}
                            errorMessage={errors?.phone?.message}
                          />
                          <InputTextField
                            label={"Message"}
                            type={"text"}
                            {...register("message", { required: true })}
                            aria-invalid={errors.message ? "true" : "false"}
                            isInvalid={!!errors?.message}
                            errorMessage={errors?.message?.message}
                          />
                        </div>
                        <ContactWrapShape />
                        <SendBtn type="submit">Send</SendBtn>
                      </form>
                    ) : (
                      <ThankuModal
                        message={"Thank you for booking!"}
                        btnText={"close"}
                        handleClick={() => {
                          onOpenChange();
                          setThankyou((prev) => !prev);
                        }}
                      />
                    )}
                  </ModalBody>
                </>
              )}
            </ModalContent>
          </Modal>
        )}
      </Layout>
    </>
  );
};

export default LpTalks;
