"use client";

import { useState, useCallback } from "react";

const LazyLoader = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Use callback ref to attach observer without the need for a wrapping div
  const setRef = useCallback((node) => {
    if (!node) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once visible
        }
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    observer.observe(node);

    return () => observer.disconnect();
  }, []);

  // Render content only when it's visible, using Fragment to avoid extra wrappers
  return <>{isVisible ? children : <div style={{
    height: "50px"
  }} ref={setRef} />}</>;
};

export default LazyLoader;
