"use client"; // Marks this as a client-side component
import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { Avtar, Para, Stack } from "../molecules";
import { getClients } from "../../actions/clientSide";
import { getImageUrlNew, splitArrayIntoFour } from "../../lib/util";
import OurClientsSkeleton from "../../components/skeletons/OurClientsSkeleton";

const ClientsSlider = dynamic(() => import("./ClientsSlider"));
const UpskillYourTeam = dynamic(() => import("../modal/UpskillYourTeam"));

const OurClients = ({ params, searchParams }) => {
  const [clients, setClients] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const res = await getClients(params);
        setClients(splitArrayIntoFour(res?.clients || []));
        setResponse(res);
      } catch (error) {
        console.error("Error fetching clients:", error);
      } finally {
        setLoading(false); // Data has loaded
      }
    };

    fetchClients();
  }, [params]);

  if (loading) {
    return <OurClientsSkeleton />; // Render skeleton while loading
  }

  return (
    <div className="py-default">
      <div className="grid lg:grid-cols-5 gap-8 lg:gap-12 p-10 lg:p-20 bg-[#1A1414]">
        {clients?.length > 0 && (
          <div className="col-span-1 lg:col-span-3 overflow-hidden relative place-content-center">
            <div className="absolute z-10 inset-0 h-full bg-gradient-transparent" />
            <div className="grid grid-rows-4 gap-y-12 p-default">
              {clients.map((client, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <ClientsSlider
                    logos={client?.map((client) => ({
                      src: getImageUrlNew(client?.attachment, "thumbnail"),
                      alt: client?.title || "Not found",
                    }))}
                    reverse={rowIndex % 2 === 0}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        <div className="col-span-1 lg:col-span-2 flex flex-col justify-end order-first lg:order-last">
          <Stack className="space-y-6">
            <Avtar />
            <p className="w-full text-[40px] leading-10 font-extrabold text-white">
              {response?.title}
            </p>
            <Para textLight>{response?.description}</Para>
            <UpskillYourTeam searchParams={searchParams} />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
