"use client";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImageUrlNew } from "../lib/util";
import { Autoplay, Navigation } from "swiper/modules";
import Image from "next/image";

const AccreditationsCarousel = ({ affiliations }) => {
  const [isMobile, setIsMobile] = useState(false); // Assuming mobile is < 768px

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="block sm:hidden">
        <Swiper
          direction={"vertical"}
          loop={true}
          centeredSlides
          autoplay={{
            delay: 20,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[Navigation, Autoplay]}
          className="w-full accreditationSlider"
          breakpoints={{
            320: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
          }}
        >
          {affiliations?.data?.map((aff, index) => (
            <SwiperSlide
              className="relative !flex justify-center md:justify-normal"
              key={index}
            >
              <div className="relative w-28 h-14 overflow-hidden">
                <Image
                  key={aff?.id}
                  src={getImageUrlNew(aff?.logo, "thumbnail")}
                  fill
                  objectFit="contain"
                  className="w-full h-full"
                  alt=""
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden sm:block">
        <Swiper
          className="w-full accreditationSlider"
          loop={true}
          centeredSlides
          autoplay={{
            delay: 20,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[Navigation, Autoplay]}
          onSwiper={(swiper) => {}}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 50,
            },
          }}
        >
          {affiliations?.data?.map((aff, index) => (
            <SwiperSlide
              className="relative flex justify-center md:justify-normal !h-[14%]"
              key={index}
            >
              <div className="relative w-28 h-14 overflow-hidden">
                <Image
                  key={aff?.id}
                  src={getImageUrlNew(aff?.logo, "thumbnail")}
                  fill
                  objectFit="contain"
                  className="w-full h-full"
                  alt=""
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default AccreditationsCarousel;
